/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
    margin: 0;
    font-family: var(--bs-body-font-family);
}

.snackbar { 
    --mdc-snackbar-container-color: rgb(175, 2, 2); 
    --mdc-snackbar-supporting-text-color: white;
    --mdc-snackbar-supporting-text-weight: 500;
    --mat-mdc-snack-bar-button-color: white;
    --mdc-snackbar-supporting-text-size: 12pt;
}

/* Core Data Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';