@use "@angular/material" as mat;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat.core();

// Define pallete

$md-template-primary: (
  50: #e3e4f2,
  100: #b8bcdd,
  200: #898fc7,
  300: #5a62b1,
  400: #3641a0,
  500: #131f8f,
  600: #111b87,
  700: #0e177c,
  800: #0b1272,
  900: #060a60,
  A100: #9194ff,
  A200: #5e62ff,
  A400: #2b30ff,
  A700: #1217ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-template-primary-dark: (
  50: #e7f7f5,
  100: #c4ece7,
  200: #9ddfd7,
  300: #76d2c6,
  400: #58c8ba,
  500: #3bbeae,
  600: #35b8a7,
  700: #2daf9d,
  800: #26a794,
  900: #199984,
  A100: #cffff6,
  A200: #9cffed,
  A400: #69ffe4,
  A700: #50ffe0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-template-warn: (
  50: #fef0f0,
  100: #fddada,
  200: #fbc2c2,
  300: #f9aaaa,
  400: #f89797,
  500: #f78585,
  600: #f67d7d,
  700: #f57272,
  800: #f36868,
  900: #f15555,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffecec,
  A700: #ffd3d3,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-template-accent: (
  50: #e1f0fc,
  100: #b3d9f8,
  200: #81c0f3,
  300: #4fa6ee,
  400: #2993ea,
  500: #0380e6,
  600: #0378e3,
  700: #026ddf,
  800: #0263db,
  900: #0150d5,
  A100: #fcfdff,
  A200: #c9daff,
  A400: #96b7ff,
  A700: #7da5ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-template-primary: mat.m2-define-palette($md-template-primary);
$md-template-primary-dark: mat.m2-define-palette($md-template-primary-dark);
$md-template-warn: mat.m2-define-palette($md-template-warn);
$md-template-accent: mat.m2-define-palette($md-template-accent);

$my-typography: mat.m2-define-typography-config();
@include mat.typography-hierarchy($my-typography);

// Define theme
$md-template-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $md-template-primary-dark,
      accent: $md-template-accent,
      warn: $md-template-warn,
    ),
    typography: $my-typography,
  )
);

// background color
@function modify-background($theme, $background-color) {
  // Replace the background in the color object's background palette.
  $theme-color: map-get($theme, color);
  $color-background-palette: map-get($theme-color, background);
  $color-background-palette: map-merge(
    $color-background-palette,
    (
      background: $background-color,
    )
  );
  $color-background-palette: map-merge(
    $color-background-palette,
    (
      raised-button: $background-color,
    )
  );

  // Replace the background in the background palette.
  $background-palette: map-get($theme, background);
  $background-palette: map-merge(
    $background-palette,
    (
      background: $background-color,
    )
  );
  $background-palette: map-merge(
    $background-palette,
    (
      raised-button: $background-color,
    )
  );

  // Merge the changes into a new theme.
  $modified-theme-color: map-merge(
    $theme-color,
    (
      background: $color-background-palette,
    )
  );
  $modified-theme: map-merge(
    $theme,
    (
      color: $modified-theme-color,
    )
  );
  $modified-theme: map-merge(
    $modified-theme,
    (
      background: $background-palette,
    )
  );

  @return $modified-theme;
}

$md-template-theme: modify-background($md-template-theme, #f7f7f7);

$md-template-theme-dark: mat.m2-define-dark-theme(
  (
    color: (
      primary: $md-template-primary-dark,
      accent: $md-template-accent,
      warn: $md-template-warn,
    ),
  )
);

@media (prefers-color-scheme: light) {
  @include mat.all-component-themes($md-template-theme);
  :root {
    --primary-color: #194c46;
    --primary-color-darker: #123632;
    --primary-color-lighter: #33988c;
    --accent: #098677;
    --text-color: #222222;
    --text-color-inverse: whitesmoke;
    --text-color-light: #4e4e4e;
    --nav-text-color: #ffffff;
    --hover: #dadada;
    --hover-darker: #c8c8c8;
    --background-color: #f7f7f7;
    --background-color-with-slight-opacity: rgba(247, 247, 247, 0.5);
    --notification-view-background-color: rgba(231, 231, 231, 0.8);
    --table-header-template-background: rgba(248, 163, 35, 0.5);
    --table-header-template-color: #000000;
    --table-row-template-background: rgba(8, 7, 6, 0.2);
    --table-row-template-color: #000000;
    --table-boxshadow: 0 0 20px rgba(0, 0, 0, 0.15);
    --mat-select-panel-background-color: #ffffff;
    --gradient-animation-color-start: #5d86ff;
    --gradient-animation-color-end: #194c46;
    --user-message-color: #74cac0;
    --backend-message-color: #d9d9d9;
    --success-color: #4caf50;
    --warning-color: #ff9800;
    --error-color: #f44336;
  }
}

@media (prefers-color-scheme: dark) {
  @include mat.all-component-colors($md-template-theme-dark);
  :root {
    --primary-color: #1d887a;
    --primary-color-darker: #117769;
    --primary-color-lighter: #36a899;
    --accent: #131f8f;
    --nav-text-color: whitesmoke;
    --text-color: whitesmoke;
    --text-color-inverse: #222222;
    --text-color-light: #b0b0b0;
    --hover: #3a3a3a;
    --hover-darker: #2a2a2a;
    --background-color: #4a4a4a;
    --background-color-with-slight-opacity: rgba(74, 74, 74, 0.5);
    --notification-view-background-color: rgba(74, 74, 74, 0.8);
    --table-header-template-background: rgba(84, 50, 0, 0.5);
    --table-header-template-color: rgb(255, 247, 234);
    --table-row-template-background: rgba(248, 163, 35, 0.2);
    --table-row-template-color: whitesmoke;
    --table-boxshadow: 0 0 12px 3px rgba(255, 255, 255, 0.1);
    --mat-select-panel-background-color: #333333;
    --gradient-animation-color-start: #36a899;
    --gradient-animation-color-end: whitesmoke;
    --user-message-color: #0e6b60;
    --backend-message-color: #686868;
    --success-color: #4caf50;
    --warning-color: #ff9800;
    --error-color: #f44336;
  }
}

/** SCROLLBAR STYLE **/

@-moz-document url-prefix() {
  * {
    scrollbar-color: var(--primary-color-lighter) var(--hover);
  }
}

* {
  &::-webkit-scrollbar {
    width: 7px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: var(--hover); /* Background of the scrollbar track */
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary-color-lighter); /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners of the scrollbar thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(
      --primary-color
    ); /* Color of the scrollbar thumb on hover */
  }
}

.low-density-input {
  @include mat.form-field-density(-4);
}
